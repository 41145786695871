import { ChangeDetectionStrategy, Component, HostListener, Inject, NgZone, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import Bugsnag from '@bugsnag/js';
import { Router } from '@angular/router';
import {ApiResponse, OfflineServiceInterface, ThemeServiceInterface, UserServiceInterface} from '@hutsix/ngxh6';
import { ApiServiceInterface } from '@hutsix/ngxh6';
import { UserInterface } from './_generated/api_interfaces/api/user.interface';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    public showRequests = !environment.production;
    public online: boolean = true;

    @HostListener('window:beforeunload', ['$event'])
    handleBeforeUnload($event: BeforeUnloadEvent): any {
        if (this.user.offline_lock && this.user.offlineSyncing) {
            const confirmationMessage = 'Offline sync is running. Are you sure?';
            $event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
    }

    @HostListener('window:unload', ['$event'])
    handleUnload($event: BeforeUnloadEvent): any {
        if (this.user.offline_lock && this.user.offlineSyncing) {
            localStorage.setItem('clearOffline', JSON.stringify(true));
        }
    }

    @HostListener('window:load', ['$event'])
    handleLoad($event: BeforeUnloadEvent): any {
        if (JSON.parse(localStorage.getItem('clearOffline')) === true) {
            localStorage.setItem('clearOffline', JSON.stringify(false));
            this.user.offline_lock = false;
        }
    }

    @HostListener('touchstart', ['$event']) onTouchStart(event: TouchEvent): void {
        // is not near edge of view, exit
        const x = event.touches[0].pageX;
        if (x > 10 && x < window.innerWidth - 10) return;

        // prevent swipe to navigate gesture
        event.preventDefault();
    }

    constructor(
        @Inject('ApiService') public api: ApiServiceInterface,
        @Inject('OfflineService') private offline: OfflineServiceInterface,
        @Inject('UserService') public user: UserServiceInterface,
        @Inject('ThemeService') public themeService: ThemeServiceInterface,
        private swUpdate: SwUpdate,
        private toastr: ToastrService,
        private zone: NgZone,
        private router: Router,
    ) {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(event => {
                console.log('New update available');
                this.toastr
                    .info('Click To Refresh', 'APP Updated!', {
                        disableTimeOut: true,
                    })
                    .onTap.pipe(take(1))
                    .subscribe(() => this.updateToLatest());
            });
        }

        this.zone.runOutsideAngular(() => {
            setInterval(() => {
                this.checkForUpdate();
            }, 10000);
        });
    }

    ngOnInit(): void {
        let first = true;
        this.user.watch.subscribe(user => {
            if (!user.loggedIn && !first) this.user.reloadRoute('/login');
            if (
                user.loggedIn &&
                (first ||
                    (user.account.impersonating && user.account.user.email !== user.account.impersonating) ||
                    (!user.account.impersonating && user.account.user.email !== user.account.email))
            ) {
                // This should only run once.
                first = false;
                // update the user
                this.api
                    .get({ url: '/api/v1/user/current', useCache: false, displayErrors: false })
                    .subscribe((result: ReturnType<UserInterface['apiUserCurrent']>) => this.user.updateUserData(result.data));
                // track offline status
                this.offline.stateChange.subscribe(status => {
                    this.online = status;
                    if (status) {
                        this.offline.getOfflineQueue().subscribe(res => {
                            if (res.length) {
                                this.toastr
                                    .info('Click to sync offline data.', 'Connection detected!', {
                                        disableTimeOut: true,
                                    })
                                    .onTap.pipe(take(1))
                                    .subscribe(() => this.router.navigateByUrl('/user-admin/offline'));
                            }
                        });
                    }
                });
            }
        });

        Bugsnag.addOnError(event => {
            if (this.user.loggedIn) event.setUser(this.user.user.id, this.user.user.email, this.user.user.name);
        });
    }

    private checkForUpdate(): void {
        if (this.swUpdate.isEnabled) {
            this.swUpdate
                .checkForUpdate()
                .then(() => {
                    // console.log('Checking for updates...');
                })
                .catch(err => {
                    console.error('Error when checking for update', err);
                });
        }
    }

    private updateToLatest(): void {
        console.log('Updating to latest version.');
        this.swUpdate.activateUpdate().then(() => document.location.reload());
    }
}
