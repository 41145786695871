import { CrudOptions } from '@hutsix/ngxh6';
import cruds_conf from '../_generated/ext_config/cruds.json';

/** Override generated CRUD values here */
const cruds_conf_overrides: { [key: string]: Partial<CrudOptions> } = {
  examples: {
    icon: 'fa-object-group',
    tableSpec: {
      actionUrl: './',
      columns: [
        {
          header: '',
          type: 'usericon',
          value: 'createdBy',
          style: { 'min-width': '50px' },
        },
        {
          header: 'Created By',
          type: 'objvalue',
          typeOption: 'name',
          value: 'createdBy',
          style: { 'min-width': '120px' },
          class: 'text-nowrap',
        },
        { header: 'Number', value: 'number', class: 'text-nowrap' },
        { header: 'Name', value: 'name', class: 'text-nowrap', linkAction: true },
        { header: 'Category', type: 'objvalue', typeOption: 'name', value: 'category', class: 'text-nowrap' },
        {
          header: 'Date',
          type: 'date',
          typeOption: 'short',
          value: 'created',
          class: 'text-nowrap',
        },
        {
          header: 'Array',
          type: 'arraypluck',
          typeOption: 'name',
          value: 'exampleCollection',
          style: { 'width': '100%', 'min-width': '150px' },
        },
      ],
    },
  },
};

/** Create custom CRUDS or override generated forms here */
const cruds = {
  test: new CrudOptions({
    objectLabel: 'Test',
    apiEndpoint: '/api/v1/examples',
  }),
};

export function getCrudsConfig() {
  const c = JSON.parse(JSON.stringify(cruds_conf));
  for (const [k, v] of Object.entries(cruds_conf_overrides)) {
    if (c[k]) {
      c[k] = Object.assign(cruds_conf[k] || {}, v);
    } else {
      console.warn(`Attempt to override generated CRUD failed. Key '${k}' doesn't exist`);
    }
  }
  return { ...c, ...cruds };
}

declare global {
  type CrudKeys = keyof typeof cruds | keyof typeof cruds_conf;
}
